html body {
  font-weight: 500;
  background: #f0f0f0;
}

.nav .dropdown.show .dropdown-toggle::after, .dropdown-toggle::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

::-webkit-scrollbar-track {
  background: transparent !important;
  border-radius: 8px !important;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 8px #75757521 !important;
  border-radius: 8px !important;
  border: 4px solid transparent !important;
  opacity: .5 !important;
}

::-webkit-scrollbar-corner {
  opacity: 0 !important;
  visibility: hidden !important;
}

#WEB3_CONNECT_MODAL_ID {
  .web3modal-modal-lightbox {
    z-index: 9999 !important;
  }
}

.apexcharts-tooltip {
  display: none !important;
  opacity: 0 !important;
}

::-webkit-scrollbar {
  width: 16px !important;
  height: 16px !important;
}

ngx-skeleton-loader .loader {
  background-color: rgba(0,0,0,0.1) !important;
}

.dark-layout ngx-skeleton-loader .loader {
  background-color: rgba(255,255,255,0.1) !important;
}

:focus-visible, button:focus {
  outline: none !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-30 {
  opacity: 0.3 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-60 {
  opacity: 0.6 !important;
}

.opacity-70 {
  opacity: 0.7 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.modal-open .modal {
  z-index: 999999;
}

.text-danger {
  color: #f6465d !important;
  font-weight: bolder !important;
}

.text-success {
  color: #0ecb81 !important;
  font-weight: bolder !important;
}

.cursor-default {
  cursor: default !important;
}

.main-menu.menu-light .navigation > li.active > a {
  box-shadow: none;
  // background: transparent;
}

.main-menu.menu-light .navigation > li ul .active {
  box-shadow: none;
  // background: transparent;
}

.nav-tabs {
  .nav-item {
    margin-right: 1.5rem !important;
    &:last-child {
      margin-right: 0 !important;
    }
    .nav-link {
      padding: 0.5rem 0 !important;
    }
  }
}

.table-responsive {
  // overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  table {
    th,td {
      white-space: nowrap;
    }
  }
}

.bg-light-dark {
  background-color: #fff !important;
}

.modal-backdrop {
  &.show {
    opacity: 1 !important;
    // background: rgba(34,41,47,.75);
  }
}

.modal {
  top: 0 !important;
  -webkit-animation: none !important;
  animation: none !important;
}

.modal {
  .modal-dialog {
    .modal-content {
      border-radius: 1.5rem !important;
      overflow: hidden;
    }
  }
}

.footer.footer-light.footer-static {
  background-color: #dfe7fb !important;
  background: linear-gradient(-138deg, #0e269c 0%, #0a208f 100%) !important;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav {
    justify-content: center;
  }
}

.app-content.content {
  // max-width: 1440px;
  // margin: auto !important;
}

html .navbar-sticky .app-content {
  padding: calc(2rem + 4.45rem) 2rem 0 2rem !important;
}

.body-home-page {
  overflow-x: hidden;
}

.body-home-page .app-content.content {
  max-width: 100% !important;
  margin: auto !important;
}

// html .body-home-page .navbar-sticky .app-content {
//   padding: calc(2rem + 4.45rem) 0 0 0 !important;
// }

.input-group:focus-within .form-control,
.input-group:focus-within .input-group-text {
  border-color: #d8d6de !important;
}

body {
  font-family: "Quicksand", Helvetica, Arial, serif !important;
}

html body {
  //background-color: #f2f8ff;
  // background-color: #fcfcfc;
  // background: #f2f8ff;
}

.dropdown-menu .dropdown-item {
  width: 100% !important;
}

.card {
  // box-shadow: none !important;
  // border-radius: 1.5rem !important;
  border-radius: 0.4rem !important;
}

.horizontal-layout.navbar-sticky .horizontal-menu-wrapper .navbar-horizontal.header-navbar.fixed-top {
  box-shadow: none !important;
}

.horizontal-layout.navbar-sticky .header-navbar {
  background-color: #fff !important;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header {
  position: relative!important;
  left: 0!important;
  padding: 0 2rem!important;
}

ul.navbar-nav li.dropdown .dropdown-menu,
.dropdown-menu {
  width: 100% !important;
  overflow: hidden !important;
}

.horizontal-menu .horizontal-menu-wrapper {
  display: none;
}

.header-navbar {
  font-family :"Quicksand", Helvetica, Arial, serif !important;
}

.menu-main-header {
  cursor: pointer;
  font-size: 1.25rem;
  transition: all .3s;
  font-weight: 800;
  .feather {
    width: 17px;
    height: 17px;
    margin-right: 2px;
  }
  &:hover, &.active {
    // line-height: normal;
    -webkit-text-fill-color: transparent;
    font-weight: 800;
    color: transparent;
    text-shadow: none;
    background: black;
    /*background: -webkit-linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
    background: -moz-linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
    background: linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
    -webkit-background-clip: text;*/
    -webkit-text-fill-color: transparent;
  }
}

.menu-desktop-only.active.dropdown .dropdown-toggle {
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  color: transparent;
  text-shadow: none;
  background: black;
  /*background: -webkit-linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
  background: -moz-linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
  background: linear-gradient(45deg, #00c6ff 0%, #0072ff 100%);
  -webkit-background-clip: text;*/
  -webkit-text-fill-color: transparent;
}

.dropdown-item:hover, .dropdown-item:focus {
  background: black;
  //background: linear-gradient(300deg, #00c6ff 0%, #0072ff 100%);
  color: #fff;
  .text-white-main {
    color: #fff !important;
  }
  small {
    color:white;
  }
}

.dropdown-menu .dropdown-item:active, .dropdown-menu .dropdown-item.active {
  background: black;
  //background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  color: #fff;
  .text-white-main {
    color: #fff !important;
  }
}

.brand-text{
  color:black !important;
}

.brand-logo {
  img {
    //filter: invert(68%) sepia(100%) saturate(1576%) hue-rotate(210deg) brightness(98%) contrast(97%);
    width: 15px;
  }
}

.modal .modal-header {
  background-color: #f2f8ff;
}

.input-group:not(.bootstrap-touchspin):focus-within {
  box-shadow: none !important;
}

.text-white-main {
  color: #000 !important;
}

.btn-gradient-custom {
  max-height: 40px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(240, 242, 242);
  font-weight: 700;
  // background-image: linear-gradient(rgb(249, 250, 250), rgb(249, 250, 250)), linear-gradient(263.72deg, #2669F5 -10.41%, #7ec8df 30.52%, #e238ff 108.2%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border: 1px solid transparent;
  border-radius: 0.357rem;
  cursor: pointer;
  button {
    color: #000 !important;
  }
}

.modal-connect-wallet {
  .modal-content {
    border-radius: 20px;
    background: #fff;
    overflow: hidden;
    .modal-body {
      border-radius: 20px;
      background: transparent;
      .btn-connect-wallet-modal {
        padding: 30px 10px;
        width: 100%;
        border: 0;
        background: transparent;
        &:first-child {
          border-bottom: 1px solid #c3c3c324;
        }
        &:last-child {
          border-top: 1px solid #c3c3c324;
        }
        &:hover {
          background-color: #c3c3c324;
        }
        .img-connect-wallet {
          width: 50px;
          height: 50px;
          display: flex;
          border-radius: 50%;
          overflow: visible;
          box-shadow: none;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          margin: auto;
        }
        .title-connect-wallet {
          width: 100%;
          font-size: 21px;
          font-weight: 500;
          margin-top: 0.2em;
          color: rgb(12, 12, 13);
        }
        .desc-connect-wallet {
          width: 100%;
          font-size: 17px;
          margin: 0.1em 0px;
          color: rgb(169, 169, 188);
        }
      }
    }
  }
}

// .dark {
//   .modal-connect-wallet {
//       .modal-content {
//         .modal-body {
//           .btn-connect-wallet-modal {
//             .title-connect-wallet {
//               color: rgb(255, 255, 255);
//             }
//           }
//         }
//       }
//   }
// }

// .btn-gradient-custom {
//   background-image: linear-gradient(#283046, #283046), linear-gradient(263.72deg, #2669F5 -10.41%, #7ec8df 30.52%, #e238ff 108.2%);
//   button {
//     color: #fff !important;
//   }
// }

.modal .modal-header .close {
  transform: translate(-18px, 6px);
}

.modal .modal-header .close:hover, .modal .modal-header .close:focus, .modal .modal-header .close:active {
  transform: translate(-18px, 2px);
}

.btn-flat-secondary {
  color: #000 !important;
}
// .nav .dropdown-toggle:not(.active)::after {
//   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
// }

.text-focus-main {
  color: #000 !important;
}

.empty-data-wrapper {
  min-height: 200px;
  height: 100%;
  padding-bottom: 20px;
}

.dropdown-menu {
  padding: 0 !important;
}

.apexcharts-svg {
  overflow: hidden;
  border-radius: 1.5rem !important;
}

.btn-group-toggle label[class*=btn-outline-] {
  border-color: rgb(236, 239, 243) !important;
  color: rgb(124, 132, 151) !important;
}

.btn-group-toggle input.form-control {
  border-color: rgb(236, 239, 243) !important;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):focus {
  background-color: #2669F5 !important;
  color: #fff !important;
  border-color: #2669F5 !important;
}

.horizontal-menu .header-navbar.navbar-brand-center .navbar-header .navbar-brand .brand-text {
  padding-left: 0.5rem !important;
  font-size: 1.75rem !important;
}

@media (max-width: 1200px) {
  .menu-desktop-only {
    display: none !important;
  }
  .menu-mobile-only {

  }
}

@media (max-width: 1199px) {
  .horizontal-menu .horizontal-menu-wrapper {
    display: block;
    z-index: 999999 !important;
  }
}

@media (max-width: 576px) {
  .modal {
    padding-right: 0 !important;
    padding-left: 0 !important;
    .modal-dialog {
      width: 100% !important;
      margin: 0 !important;
      .modal-content {
        border-radius: 0 !important;
      }
    }
  }
  .modal-body-manage-token-list {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.text-gradient-main {
  line-height: normal;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  color: transparent;
  text-shadow: none;
  background: #121FCF;
  background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-gradient-primary {
  //background: linear-gradient(to right, #4568DC 0%, #B06AB3 100%) !important;
  background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  border-color: #00c6ff !important;
}

.wrapper-summary {
  background: #f2f8ff !important;
}

.trade-menu-tab {
  a {
    opacity: 0.7;
    position: relative;
    font-size: 1.1rem;
    margin-right: 1.25rem;
    &:last-child {
      margin-right: 0 !important;
    }
    &:hover {
      color: #90acf0 !important;
      opacity: 1;
    }
    &.active {
      font-weight: bold;
      opacity: 1;
      color: inherit !important;
      &::after {
        position: absolute;
        bottom: -7px;
        left: 0;
        width: 100%;
        height: 2px;
        background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
        background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
        background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
        // background: linear-gradient(to bottom, #2669F5 0%, #3a01ab 100%) !important;
        content: '';
      }
    }
  }
}

.dark-layout {

  .menu-main-header {
    &:hover, &.active {
      background: #2669F5;
      background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
      background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
      background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .menu-desktop-only.active.dropdown .dropdown-toggle {
    background: #2669F5;
    background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .content-wrapper {
    .nav-type {
      .active-type {
        background: #2669F5;
        background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
        background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
        background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &::after {
          background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
          background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
          background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
        }
      }
    }
  }

  .input-group:focus-within .form-control,
  .input-group:focus-within .input-group-text {
    border-color: #404656 !important;
  }

  .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus {
    background: #2669f5;
    color: #fff !important;
    // background: linear-gradient(144deg, #e238ff 0%, #2669f5 100%) !important;
    background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  }

  .swal2-popup.swal2-toast {
    background-color: #283046;
    box-shadow: 0 0 0.625em #161d31;
  }

  .trade-menu-tab {
    a.active {
      color: #fff !important;
    }
  }

  .nav-type a {
    opacity: 0.7;
    &.active-type {
      opacity: 1;
      color: #fff;
    }
  }
  .nav-tabs .nav-item .nav-link {
    opacity: 0.7;
  }
  .nav-tabs .nav-item .nav-link.active, .dark-layout .nav-pills .nav-item .nav-link.active, .dark-layout .nav-tabs.nav-justified .nav-item .nav-link.active {
    color: #fff !important;
    opacity: 1;
  }

  .nav-tabs .nav-link:after {
    background: -webkit-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    background: -moz-linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
    background: linear-gradient(135deg, #00c6ff 0%, #0072ff 100%);
  }


  .badge.badge-light-primary {
    color: #58aaff !important;
  }
  .dropdown-menu {
    background-color: #283046;
  }
  input {
    background: #161d31;
    color: #fff;
  }
  .bg-main {
    background: #161d31 !important;
  }
  .bg-sub {
    background: #283045 !important;
  }
  .nav-type {
    color: #fff;
  }
  .border-sub {
    border: 1px solid #39434c !important;
  }
  .brand-text {
    color: #fff !important;
  }
  .nav-category {
    button {
      background-color: transparent;
      &.active-cate {
        background: #161d31 !important;
        color: #f0f0f0;
      }
    }
  }
  .brand-logo {
    img {
      filter: none;
    }
  }
  .route-content {
    ul {
      li {
        &::after {
          border-right: 2px dashed #7f7f7f;
        }
        &::before {
          border-left: 2px dashed #7f7f7f;
        }
        div {
          &::before {
            border-top: 2px dashed #7f7f7f;
          }
        }
      }
    }
  }
  .route-content ul li div:after,.wrapper-route .token-name-route-destination::after {
    background: #161d31 !important;
  }
  .text-white-main {
    color: #fff !important;
  }
  .text-white-sub {
    color: #b4b7bd !important;
  }
  .bg-light-dark {
    background-color: #161d31 !important;
  }
  .btn-group-toggle label[class*=btn-outline-] {
    border-color: rgb(58, 72, 97) !important;
    color: rgb(124, 132, 151) !important;
  }
  .btn-group-toggle input.form-control {
    border-color: rgb(58, 72, 97) !important;
  }
  .wrapper-summary {
    background: #161d31 !important;
  }
  .wrapper-btn-action-header {
    button {
      color: #b4b7bd !important;
      &:hover, &:focus {
        color: #d0d2d6 !important;
      }
    }
  }
  .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link {
    color: #b4b7bd !important;
    &:hover , &:focus {
      color: #d0d2d6 !important;
    }
  }
  .wrapper-btn-toggle-eye {
    button {
      background: #161d31;
    }
  }
  .modal-body-search {
    background-color: #161d31 !important;
  }
  .icon-swap {
    background: #161d31;
  }
  .btn-flat-secondary {
    color: #fff !important;
  }
  .text-focus-main {
    color: #fff !important;
  }
  .horizontal-layout.navbar-sticky .header-navbar {
    background-color: #283046 !important;
  }
  .table-custom-main {
    tr {
      td {
        &:first-child {
          font-weight: bolder;
          color: #fff !important;
        }
      }
    }
  }
  .tradeform {
    .input-trade {
      background: #161d31;
    }
    .input-group-prepend {
      // margin-right: 0;
      &.dropup button,
      &.dropright button,
      &.dropleft button,
      &.dropdown button,
      .input-group-text {
        background: #161d31;
      }
    }
    .input-group {
      background: #161d31;
    }
  }
  .dropdown-toggle::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
  }
}

.main-menu.menu-light .navigation > li.active > a {
  background: black;
}
.header-navbar .navbar-container ul.navbar-nav li a.dropdown-user-link img {
  box-shadow: none;
  border: 1px solid black;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row {
  border-top: none !important;
  border-bottom: 1px solid #ebe9f1 !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-header {
  background-color: #f3f2f7 !important;
  text-transform: uppercase !important; 
  border-top: 1px solid #ebe9f1 !important;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-bottom: 1px solid #ebe9f1 !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row.datatable-row-even {
  background-color: transparent !important;
  background-color: initial !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer {
  background-color: #fff;
  color: #636363;
  overflow: hidden;
  margin-bottom: 1rem;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager {
  background-color: #f3f2f7;
  border-top-left-radius: 28px;
  border-bottom-left-radius: 28px;
  border-top-right-radius: 28px;
  border-bottom-right-radius: 28px;
  height: 36px;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li {
  margin: 2px 0 11px !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li.disabled a {
  color: #b9b9c3;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li.disabled a {
  color: #b9b9c3;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active:hover {
  vertical-align: sub;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager a {
  color: #636363;
  line-height: 18px;
  font-size: 14px;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell,
.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding: 0.75rem 1rem !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: #545454;
  font-weight: 700;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active:hover a, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a {
  background-color: #000 !important;
  color: #fff !important;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0 5px;
  line-height: 32px;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left, .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip, .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right, .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev {
  line-height: normal !important;
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: transparent !important;
  color: #333 !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .page-count {
  color: #b9b9c3;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000000;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media print {
  .custom-print-mode {
    @page {
      size: A6;
      margin: 0mm;
    }
    html,body {
      background: #fff !important;
    }
    .content-wrapper {
      display: none !important;
    }
    .card {
      box-shadow: none !important;
    }
    .hide-print-mode {
      display: none !important;
    }
    .header-navbar, .main-menu {
      display: none !important;
    }
    .wrapper-print .card-body {
      padding: 0 !important;
    }
  }
}

.custom-print-mode {
  core-loading-screen {
    display: none !important;
  }
  app-gen-qrcode {
    svg {
      max-width: 100% !important;
      max-height: 100% !important;
    }
  }
}

.gap-25 {
  gap: 0.25rem;
}

.gap-50 {
  gap: 0.50rem;
}

.gap-75 {
  gap: 0.75rem;
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

html .navbar-static .app-content {
  padding-top: 20px !important;
}
